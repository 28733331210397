<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="9" v-if="types=='专家'">
        <h2 class="title">接诊提醒</h2>
        <el-row :gutter="20" class="mgb20">
          <el-col :span="12" v-for="(item,index) in remindList" :key="index" class="listBar">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1" @click="onNumber(item.num, item.begin, item.end)">
                <i class="el-icon-user-solid grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{item.num}}</div>
                  <div>{{item.name}}</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <h2 class="title">近期接诊统计</h2>
        <el-row :gutter="20" class="mgb20">
          <el-col :span="12" v-for="(item,index) in censusList" :key="index" class="listBar">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2" @click="onStatistics(item.num, item.begin, item.end)">
                <i class="el-icon-s-marketing grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{item.num}}</div>
                  <div>{{item.name}}</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12" v-else>
        <h2 class="title">本月待处理</h2>
        <el-row :gutter="20" class="mgb20">
          <el-col :span="12" v-for="(item,index) in todoList" :key="index" class="listBar">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1" @click="onPending(item.num, item.begin, item.end, index)">
                <i class="el-icon-user-solid grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{item.num}}</div>
                  <div>{{item.name}}</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <h2 class="title">近期工作统计</h2>
        <el-row :gutter="20" class="mgb20">
          <el-col :span="12" v-for="(item,index) in todoList2" :key="index" class="listBar">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2" @click="onPending2(item.num, index)">
                <i class="el-icon-s-marketing grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{item.num}}</div>
                  <div>{{item.name}}</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="15" v-if="types=='专家'">
        <h2 class="title">我的排班</h2>
        <el-table ref="singleTable" :data="tableData" highlight-current-row class="header" style="width: 100%">
          <el-table-column property="date" width="120"></el-table-column>
          <el-table-column>
            <template #header>
               <div>{{titleData[0]}}</div>
               <div>{{titleData1[0]}}</div>
            </template>
            <template #default="scope">
              <span v-if="scope.row.week1"><el-tag>有班</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
               <div>{{titleData[1]}}</div>
               <div>{{titleData1[1]}}</div>
            </template>
            <template #default="scope">
              <span v-if="scope.row.week2"><el-tag>有班</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
               <div>{{titleData[2]}}</div>
               <div>{{titleData1[2]}}</div>
            </template>
            <template #default="scope">
              <span v-if="scope.row.week3"><el-tag>有班</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
               <div>{{titleData[3]}}</div>
               <div>{{titleData1[3]}}</div>
            </template>
            <template #default="scope">
              <span v-if="scope.row.week4"><el-tag>有班</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
               <div>{{titleData[4]}}</div>
               <div>{{titleData1[4]}}</div>
            </template>
            <template #default="scope">
              <span v-if="scope.row.week5"><el-tag>有班</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
               <div>{{titleData[5]}}</div>
               <div>{{titleData1[5]}}</div>
            </template>
            <template #default="scope">
              <span v-if="scope.row.week6"><el-tag>有班</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
               <div>{{titleData[6]}}</div>
               <div>{{titleData1[6]}}</div>
            </template>
            <template #default="scope">
              <span v-if="scope.row.week7"><el-tag>有班</el-tag></span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { workingList, specialistAlert, nurseAlert } from "../api/index";
// import Schart from "vue-schart";
export default {
  name: "dashboard",
  data() {
    return {
      types: '专家',    
      obj:'',
      titleData:[ ], //排班日期
      titleData1:[], //排班星期
      tableData: [],  //排班数据
      remindList: [   //接诊提醒
      ],
      censusList:[   //统计
      ],
      // name: localStorage.getItem("ms_username"),
      todoList: [   //护士端
        
      ],
      todoList2: []
    };
  },
  created() {
    let types = localStorage.getItem("types");
    this.types = types
    if(types=='专家'){
      this.onWorkingList();
      this.onSpecialistAlert();
    }else{
      this.onNurseAlert()
    }
      
  },
  // components: {
  //   Schart,
  // },
  computed: {

  },

  methods: {
    // 获取排班
    onWorkingList(){
      workingList().then(res=>{
        // console.log("排班数据", res)
        res[0][0].forEach(item=>{
          this.titleData.push(item.date)
          this.titleData1.push(item.week)
        })
        this.tableData = [
          {
            "date": '09:00—10:00',
            "week1": res[0][0][0].ids,
            "week2": res[0][0][1].ids,
            "week3": res[0][0][2].ids,
            "week4": res[0][0][3].ids,
            "week5": res[0][0][4].ids,
            "week6": res[0][0][5].ids,
            "week7": res[0][0][6].ids
          },
          {
            "date": '10:00—11:00',
            "week1": res[1][0][0].ids,
            "week2": res[1][0][1].ids,
            "week3": res[1][0][2].ids,
            "week4": res[1][0][3].ids,
            "week5": res[1][0][4].ids,
            "week6": res[1][0][5].ids,
            "week7": res[1][0][6].ids
          },
          {
            "date": '11:00—12:00',
            "week1": res[2][0][0].ids,
            "week2": res[2][0][1].ids,
            "week3": res[2][0][2].ids,
            "week4": res[2][0][3].ids,
            "week5": res[2][0][4].ids,
            "week6": res[2][0][5].ids,
            "week7": res[2][0][6].ids
          },
          {
            "date": '14:00—15:00',
            "week1": res[3][0][0].ids,
            "week2": res[3][0][1].ids,
            "week3": res[3][0][2].ids,
            "week4": res[3][0][3].ids,
            "week5": res[3][0][4].ids,
            "week6": res[3][0][5].ids,
            "week7": res[3][0][6].ids
          },
          {
            "date": '15:00—16:00',
            "week1": res[4][0][0].ids,
            "week2": res[4][0][1].ids,
            "week3": res[4][0][2].ids,
            "week4": res[4][0][3].ids,
            "week5": res[4][0][4].ids,
            "week6": res[4][0][5].ids,
            "week7": res[4][0][6].ids
          },
          {
            "date": '16:00—17:00',
            "week1": res[5][0][0].ids,
            "week2": res[5][0][1].ids,
            "week3": res[5][0][2].ids,
            "week4": res[5][0][3].ids,
            "week5": res[5][0][4].ids,
            "week6": res[5][0][5].ids,
            "week7": res[5][0][6].ids
          }
        ]
      })
    },
    // 获取预约人数
    onSpecialistAlert(){
      specialistAlert().then(res=>{
        this.obj = res
        this.remindList = [   //接诊提醒
          {'name':'预约上午人数', 'num': res.forenoon, 'begin': res.forenoon_begin, 'end': res.forenoon_end},
          {'name':'预约下午人数', 'num': res.afternoon, 'begin': res.afternoon_begin, 'end': res.afternoon_end},
          {'name':'预约近3天人数', 'num': res.three, 'begin': res.three_begin, 'end': res.three_end},
          {'name':'预约近7天人数', 'num': res.seven, 'begin': res.seven_begin, 'end': res.seven_end}
        ]
        this.censusList = [   //统计
          {'name':'今日接诊人数', 'num': res.today, 'begin': res.today_begin, 'end': res.today_end},
          {'name':'近7天接诊人数', 'num': res.sevens, 'begin': res.sevens_begin, 'end': res.sevens_end},
          {'name':'本月接诊人数', 'num': res.month, 'begin': res.month_begin, 'end': res.month_end},
          {'name':'本季度接诊人数', 'num': res.quarter, 'begin': res.quarter_begin, 'end': res.quarter_end},
        ]
      })
    },
    onNumber(num, begin, end){
      if(Number(num)>0){
        this.$router.push({path: '/registerList',query:{begin:begin, end:end, id: this.obj.medic_id}})
      }else{
        this.$message("暂无接诊人数");
      }
    },
    onStatistics(num, begin, end){
      if(Number(num)>0){
        this.$router.push({path: '/curativeList',query:{begin:begin, end:end, id: this.obj.medic_id}})
      }else{
        this.$message("暂无接诊统计");
      }
    },

    // 获取护士端排班
    onNurseAlert(){
      nurseAlert().then(res=>{
        console.log(res)
        this.todoList = [
          {'name':'待治疗人数', 'num': res.pending, 'begin': res.pending_begin, 'end': res.pending_end},
          {'name':'待确认人数', 'num': res.drug, 'begin': res.drug_begin, 'end': res.drug_end}
        ]
        this.todoList2 = [
          {'name':'今日治疗人数', 'num': res.today, 'today_time': res.today_time},
          {'name':'近7天治疗人数', 'num': res.seven, 'begin': res.seven_begin, 'end': res.seven_end},
          {'name':'本月治疗人数', 'num': res.month, 'begin': res.month_begin, 'end': res.month_end},
          {'name':'上月治疗人数', 'num': res.last_month, 'begin': res.last_begin, 'end': res.last_end}
        ]
      })
    },
    onPending(num, begin, end, index){
      if(Number(num)>0){
        if(index===0){
          this.$router.push({path: '/curativeList',query:{serve_begin:begin, serve_end:end, finish: 0, status: 4}})
        }else{
          this.$router.push({path: '/drugsList', query:{serve_begin:begin, serve_end:end}})
        }
      }else{
        this.$message("暂无待处理人数");
      }
    },
    onPending2(num, index){
      if(Number(num)>0){
        if(index===0){
          this.$router.push({path: '/curativeList',query:{today_time: this.todoList2[index].today_time, finish: 1, status: 4}})
        }else{
          this.$router.push({path: '/curativeList',query:{serve_begin: this.todoList2[index].begin, serve_end: this.todoList2[index].end, finish: 1, status: 4}})
        }
      }else{
        this.$message("暂无统计治疗人数");
      }
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 30px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}

/* add */
.title,.listBar{
  margin-bottom: 10px;
}
</style>
